import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment-timezone";
import { Container, Row, Col, Form } from "react-bootstrap";
import { Helmet } from "react-helmet";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import FormGroup from "@material-ui/core/FormGroup";
import EventBar from "../modules/event-bar";
import BreadCrumb from "../modules/breadcrumb";
import "date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import {
  createEvent,
  googleTextToSpeech,
  uploadMedia,
} from "../../actions/auth";
import TextField from "@material-ui/core/TextField";
import UserService from "../../services/user.service";
import DropZonePlaceHolder from "../modules/dropzone-placeholder";
import LoaderGif from "../modules/loader";
import ToolTipInfo from "../modules/tooltip_info";
import { Base64 } from "js-base64";
import Button from "@material-ui/core/Button";
import { ErrorMessage } from "@hookform/error-message";
import { statusErrorSuccess } from "../../App";
import category_list_json from "../../assets/json/category_list";
import timezone_list_json from "../../assets/json/timezone_list";
import cms_button_details_json from "../../assets/json/cms_button_details";
import { withStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";

const ENV_MEDIA_URL = process.env.REACT_APP_MEDIA_URL;
const ENV_EVENT_URL = process.env.REACT_APP_EVENT_URL;
const ENV_DEFAULT_LANG = process.env.REACT_APP_DEFAULT_LANG;
const ENV_ADMIN_ID = process.env.REACT_APP_ADMIN_ID;
const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 250,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}))(Tooltip);

const NewEvent = ({ match }) => {
  const {
    params: { eventType, eventId },
  } = match;
  const { session, userCollab } = useSelector((state) => state.auth);

  const [masterEventCard, setMasterEventCard] = useState(new Map());
  const [eventCardLoaded, setEventCardLoaded] = useState(false);
  const [otherEventCard, setOtherEventCard] = useState(new Map());
  const [menuItems, setMenuItems] = useState([]);
  const [masterLoaded, setMasterLoaded] = useState(false);
  const [othersLoaded, setOthersLoaded] = useState(false);
  const [duplicateCardData, setDuplicateCardData] = useState({});
  const [inputValue, setInputValue] = useState({});

  if (eventId !== "new" && !eventCardLoaded) {
    UserService.getMasterEventCard().then(
      (response) => {
        setEventCardLoaded(true);
        if (response.status === 200) {
          let mapMasterData = new Map();
          // mapMasterData.set(
          //    response.data[response.data.length - 1].access_id, response.data[response.data.length - 1]
          // );
          let k = 0;
          for (let i = response.data.length - 1; i >= 0 && k < 3; i--) {
            mapMasterData.set(response.data[i].access_id, response.data[i]);
            if (
              eventType === "master" &&
              response.data[i].access_id === eventId
            ) {
              setDuplicateCardData(response.data[i]);
            }
            k++;
          }
          setMasterEventCard(mapMasterData);
          setMasterLoaded(true);
        }
      },
      (error) => {}
    );

    if (session && session.user.role.id.toString() === ENV_ADMIN_ID) {
      UserService.getOtherEventCard().then(
        (response) => {
          if (response.status === 200) {
            let mapOtherData = new Map();
            response.data.map((i) => {
              mapOtherData.set(i.access_id, i);
              if (eventType === "others" && i.access_id === eventId) {
                setDuplicateCardData(i);
              }
            });
            setOtherEventCard(mapOtherData);
            setOthersLoaded(true);
          }
        },
        (error) => {}
      );
    } else {
      let collabId = userCollab[0].id;
      UserService.getOtherEventCardByCollabId(collabId).then(
        (response) => {
          if (response.status === 200) {
            let mapOtherData = new Map();
            response.data.map((i) => {
              let col_id_check = i.collaborator_ids;
              if (
                col_id_check.match(new RegExp("(?:^|,)" + collabId + "(?:,|$)"))
              ) {
                mapOtherData.set(i.access_id, i);
              }
              if (eventType === "others" && i.access_id === eventId) {
                setDuplicateCardData(i);
              }
            });
            setOtherEventCard(mapOtherData);
            setOthersLoaded(true);
          }
        },
        (error) => {}
      );
    }
  }

  if (masterLoaded && othersLoaded && menuItems.length === 0) {
    let temp = [];
    Array.from(masterEventCard).map(([index, event], i) => {
      temp.push({
        value: event.access_id,
        label: event.event_name,
        type: "master",
      });
      if (event.access_id === eventId) {
        setInputValue({
          value: event.access_id,
          label: event.event_name,
          type: "others",
        });
      }
    });
    Array.from(otherEventCard).map(([index, event], i) => {
      temp.push({
        value: event.access_id,
        label: event.event_name,
        type: "others",
      });
      if (event.access_id === eventId) {
        setInputValue({
          value: event.access_id,
          label: event.event_name,
          type: "others",
        });
      }
    });
    setMenuItems(temp);
  }

  if (!session) {
    return <Redirect push to="/" />;
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Create New Event | Infosys Living Labs</title>
        <meta name="description" content="" />
      </Helmet>
      <BreadCrumb
        CurrentPage="Duplicate to create a new event"
        prevPageName=""
        prevPageURL=""
      />
      {(duplicateCardData.access_id !== undefined || eventId === "new") && (
        <EventContent
          eventId={eventId}
          eventType={eventType}
          menuItems={menuItems}
          duplicateCardData={duplicateCardData}
          inputValue={inputValue}
          setInputValue={setInputValue}
          match={match}
          userCollab={userCollab}
          session={session}
        />
      )}
    </React.Fragment>
  );
};

export default NewEvent;

const EventContent = ({
  session,
  eventId,
  eventType,
  menuItems,
  duplicateCardData,
  inputValue,
  setInputValue,
  match,
  userCollab,
}) => {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const { register, handleSubmit, errors, setValue, control } = useForm({
    defaultValues: duplicateCardData,
  });
  const [categoryDropdown, setCategoryDropdown] = useState(false);
  // on submit create event
  function randomString(length, chars) {
    var result = "";
    for (var i = length; i > 0; --i)
      result += chars[Math.floor(Math.random() * chars.length)];
    return result;
  }

  function onSubmitCreateEvent(data) {
    data.access_id = randomString(
      6,
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
    );
    data.collaborator_ids = userCollab ? userCollab[0].id.toString() : "";
    let finalData = null;
    if (data.event_category !== undefined) {
      if (eventId === "new") {
        finalData = { ...data };

        /** Start, End date & timezone */
        var masterTZone =
          finalData.base_configuration[0].event_configuration
            .event_details_configuration.timezone;
        if (!masterTZone) {
          masterTZone = moment.tz.guess();
        }
        finalData.base_configuration[0].event_configuration.event_details_configuration.start_date =
          moment
            .tz(
              finalData.base_configuration[0].event_configuration
                .event_details_configuration.start_date,
              "D MMM, yyyy hh:mm a",
              masterTZone
            )
            .toISOString();
        finalData.base_configuration[0].event_configuration.event_details_configuration.end_date =
          moment
            .tz(
              finalData.base_configuration[0].event_configuration
                .event_details_configuration.end_date,
              "D MMM, yyyy hh:mm a",
              masterTZone
            )
            .toISOString();
        finalData.base_configuration[0].event_configuration.event_details_configuration.timezone =
          masterTZone;

        finalData.event_status = "Draft";
        finalData.iez_id = finalData.iez_id ? finalData.iez_id : 0;

        // Language: English
        finalData.base_configuration[0].language = ENV_DEFAULT_LANG;

        // finalData.base_configuration[0].event_configuration.event_details_configuration.event_url = ENV_EVENT_URL + data.access_id + "&lang=EN"
        finalData.base_configuration[0].event_configuration.event_details_configuration.event_url =
          data.access_id + "&lang=EN";

        /** Event Image */
        if (
          finalData.base_configuration[0].event_configuration
            .event_details_configuration.event_image &&
          finalData.base_configuration[0].event_configuration
            .event_details_configuration.event_image.id
        )
          finalData.base_configuration[0].event_configuration.event_details_configuration.event_image =
            finalData.base_configuration[0].event_configuration.event_details_configuration.event_image.id;
        else
          finalData.base_configuration[0].event_configuration.event_details_configuration.event_image =
            null;

        /** Introduction configuration */
        let intro_type =
          finalData.base_configuration[0].event_configuration
            .intro_configuration.intro_type;

        if (intro_type === "Live") {
          // set meeting URL
          // finalData.base_configuration[0].event_configuration.meeting_url = finalMeetingURL;

          // Reset Video and Audio
          finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
            null;
          finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration =
            null;
        } else if (intro_type === "Video") {
          // Video
          if (
            finalData.base_configuration[0].event_configuration
              .intro_configuration.video_configuration &&
            finalData.base_configuration[0].event_configuration
              .intro_configuration.video_configuration.intro_video
          ) {
            finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video =
              finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video.id;
          } else {
            if (
              !finalData.base_configuration[0].event_configuration
                .intro_configuration.video_configuration
            ) {
              finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
                {};
            }
            finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video =
              null;
          }

          // Reset Live and Audio
          finalData.base_configuration[0].event_configuration.intro_configuration.presenter_configuration =
            null;
          finalData.base_configuration[0].event_configuration.meeting_url = "";
          finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration =
            null;
        } else if (intro_type === "Avatar") {
          if (
            finalData.base_configuration[0].event_configuration
              .intro_configuration.avatar_configuration.voice_over_female
          ) {
            finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female =
              finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female.id;
          } else {
            finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female =
              null;
          }
          if (
            finalData.base_configuration[0].event_configuration
              .intro_configuration.avatar_configuration.voice_over_male
          ) {
            finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male =
              finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male.id;
          } else {
            finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male =
              null;
          }

          // Reset Live and Video
          finalData.base_configuration[0].event_configuration.intro_configuration.presenter_configuration =
            null;
          finalData.base_configuration[0].event_configuration.meeting_url = "";
          finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
            null;
        }
        finalData.event_collaborators = [
          { email: session.user.email, name: session.user.username },
        ];

        setLoader(true);
        // console.log(JSON.stringify(finalData));
        // console.log(finalData);

        dispatch(createEvent(finalData, "master"))
          .then(() => {
            setLoader(false);
            statusErrorSuccess("masterEventCreatedSuccess", "success");
            window.location.href = "/admin";
          })
          .catch(() => {
            setLoader(false);
            statusErrorSuccess("masterEventCreatedFailed", "danger");
          });
      } else {
        let temp =
          data.base_configuration[0].event_configuration
            .event_details_configuration;
        let temp1 =
          data.base_configuration[0].event_configuration.intro_configuration;
        let finalMeetingURL =
          data.base_configuration[0].event_configuration.meeting_url;
        delete data.base_configuration;

        finalData = {
          ...duplicateCardData,
          ...data,
        };

        // event status
        finalData.event_status = "Draft";

        // Language: English
        finalData.base_configuration[0].language = ENV_DEFAULT_LANG;

        /** Industry Vertical */
        // var e_category = temp.event_category ? temp.event_category : duplicateCardData.event_category;
        finalData.event_category = data.event_category
          ? data.event_category
          : duplicateCardData.event_category;

        /** Event URL */
        // finalData.base_configuration[0].event_configuration.event_details_configuration.event_url = ENV_EVENT_URL + data.access_id + "&lang=EN";
        finalData.base_configuration[0].event_configuration.event_details_configuration.event_url =
          data.access_id + "&lang=EN";
        finalData.base_configuration[0].event_configuration.event_details_configuration.event_name =
          finalData.event_name;
        finalData.base_configuration[0].event_configuration.event_details_configuration.event_description =
          finalData.event_description;

        /** Start, End date & timezone */
        var duplicateTZone = temp.timezone
          ? temp.timezone
          : duplicateCardData.base_configuration[0].event_configuration
              .event_details_configuration.timezone;
        if (!duplicateTZone) {
          duplicateTZone = moment.tz.guess();
        }
        finalData.base_configuration[0].event_configuration.event_details_configuration.start_date =
          moment
            .tz(temp.start_date, "D MMM, yyyy hh:mm a", duplicateTZone)
            .toISOString();
        finalData.base_configuration[0].event_configuration.event_details_configuration.end_date =
          moment
            .tz(temp.end_date, "D MMM, yyyy hh:mm a", duplicateTZone)
            .toISOString();
        finalData.base_configuration[0].event_configuration.event_details_configuration.timezone =
          duplicateTZone;

        /** Event Image */
        if (temp.event_image && temp.event_image.id) {
          finalData.base_configuration[0].event_configuration.event_details_configuration.event_image =
            temp.event_image.id;
        } else {
          finalData.base_configuration[0].event_configuration.event_details_configuration.event_image =
            null;
        }

        /** Introduction configuration */
        let intro_type = temp1.intro_type
          ? temp1.intro_type
          : finalData.base_configuration[0].event_configuration
              .intro_configuration.intro_type;
        finalData.base_configuration[0].event_configuration.intro_configuration.intro_type =
          intro_type;

        if (intro_type === "Live") {
          // Live Presenter
          finalData.base_configuration[0].event_configuration.intro_configuration.presenter_configuration =
            temp1.presenter_configuration;

          // set meeting URL
          finalData.base_configuration[0].event_configuration.meeting_url =
            finalMeetingURL;

          // Reset Video and Audio
          finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
            null;
          finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration =
            null;
        } else if (intro_type === "Video") {
          // Video
          if (
            temp1.video_configuration &&
            temp1.video_configuration.intro_video
          ) {
            if (
              !finalData.base_configuration[0].event_configuration
                .intro_configuration.video_configuration
            ) {
              finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
                {};
            }
            finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video =
              temp1.video_configuration.intro_video.id;
          } else {
            if (
              !finalData.base_configuration[0].event_configuration
                .intro_configuration.video_configuration
            ) {
              finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
                {};
            }
            finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video =
              null;
          }

          // Reset Live and Audio
          finalData.base_configuration[0].event_configuration.intro_configuration.presenter_configuration =
            null;
          finalData.base_configuration[0].event_configuration.meeting_url = "";
          finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration =
            null;
        } else if (intro_type === "Avatar") {
          // Avatar
          if (
            !finalData.base_configuration[0].event_configuration
              .intro_configuration.avatar_configuration
          ) {
            finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration =
              null;
          }
          finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration =
            temp1.avatar_configuration;

          if (temp1.avatar_configuration.voice_over_female) {
            finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female =
              temp1.avatar_configuration.voice_over_female.id;
          } else {
            finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female =
              null;
          }

          if (temp1.avatar_configuration.voice_over_male) {
            finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male =
              temp1.avatar_configuration.voice_over_male.id;
          } else {
            finalData.base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male =
              null;
          }

          // Reset Live and Video
          finalData.base_configuration[0].event_configuration.intro_configuration.presenter_configuration =
            null;
          finalData.base_configuration[0].event_configuration.meeting_url = "";
          finalData.base_configuration[0].event_configuration.intro_configuration.video_configuration =
            null;
        }

        /** Reset Area Image */
        let areaConfigs =
          finalData.base_configuration[0].event_configuration
            .area_configuration;

        areaConfigs.map((area, i) => {
          if (area.branding_images.length > 0) {
            areaConfigs[i].branding_images[0] = area.branding_images[0]
              ? area.branding_images[0].id
              : area.branding_images[0];
          }
          if (area.media_configuration) {
            if (
              finalData.base_configuration[0].event_configuration
                .intro_configuration.intro_type === "Live"
            ) {
              area.media_configuration.tv_content_type = "Wallpaper";
            }
            areaConfigs[i].media_configuration.area_intro_audio_female = area
              .media_configuration.area_intro_audio_female
              ? area.media_configuration.area_intro_audio_female.id
              : area.media_configuration.area_intro_audio_female;
            areaConfigs[i].media_configuration.area_intro_audio_male = area
              .media_configuration.area_intro_audio_male
              ? area.media_configuration.area_intro_audio_male.id
              : area.media_configuration.area_intro_audio_male;
            areaConfigs[i].media_configuration.area_intro_audio_human = area
              .media_configuration.area_intro_audio_human
              ? area.media_configuration.area_intro_audio_human.id
              : area.media_configuration.area_intro_audio_human;
            areaConfigs[i].media_configuration.area_intro_text = area
              .media_configuration.area_intro_text
              ? area.media_configuration.area_intro_text
              : null;

            if (area.media_configuration.pdf_file) {
              areaConfigs[i].media_configuration.pdf_file =
                area.media_configuration.pdf_file.id;
            }
            if (area.media_configuration.video_configuration) {
              areaConfigs[
                i
              ].media_configuration.video_configuration.preview_video = area
                .media_configuration.video_configuration.preview_video
                ? area.media_configuration.video_configuration.preview_video.id
                : area.media_configuration.video_configuration.preview_video;
              areaConfigs[
                i
              ].media_configuration.video_configuration.full_video = area
                .media_configuration.video_configuration.full_video
                ? area.media_configuration.video_configuration.full_video.id
                : area.media_configuration.video_configuration.full_video;

              let pv =
                areaConfigs[i].media_configuration.video_configuration
                  .presentation_videos;
              for (let p = 0; p < pv.length; p++) {
                pv[p]["idBackup"] = pv[p]["id"];
              }
            }
            if (
              area.media_configuration.wallpaper_configuration &&
              area.media_configuration.wallpaper_configuration.wallpaper
            ) {
              areaConfigs[
                i
              ].media_configuration.wallpaper_configuration.wallpaper =
                area.media_configuration.wallpaper_configuration.wallpaper.id;
            }
          }

          if (area.ui_configuration && area.ui_configuration.display_banner) {
            areaConfigs[i].ui_configuration.display_banner =
              area.ui_configuration.display_banner.id;
          }
        });

        finalData.base_configuration[0].event_configuration.area_configuration =
          areaConfigs;

        delete finalData.id;
        delete finalData.created_at;
        delete finalData.updated_at;
        delete finalData.published_at;
        delete finalData.event_collaborators;
        delete finalData.event_users;

        let jsonString = JSON.stringify(finalData);
        jsonString = jsonString.replace(/\"id\":[0-9]+\,?/gi, "");
        jsonString = jsonString.replace(/\"idBackup\"?/g, '"id"');
        finalData = JSON.parse(jsonString);

        setLoader(true);
        // console.log(JSON.stringify(finalData));
        // console.log(finalData);

        dispatch(createEvent(finalData))
          .then(() => {
            statusErrorSuccess("eventCreatedSuccess", "success");
            setLoader(false);
            window.location.href = "/admin";
          })
          .catch(() => {
            statusErrorSuccess("eventCreatedFailed", "danger");
            setLoader(false);
          });
      }
    } else {
      setCategoryDropdown(true);
    }
  }

  return (
    <React.Fragment>
      <Form
        className="bg_color new_create_event"
        onSubmit={handleSubmit(onSubmitCreateEvent)}
      >
        <EventBar path={match.path} title={"New Events"} session={session} />
        <EventBox
          register={register}
          errors={errors}
          control={control}
          categoryDropdown={categoryDropdown}
          setCategoryDropdown={setCategoryDropdown}
          onSubmitCreateEvent={onSubmitCreateEvent}
          setValue={setValue}
          eventId={eventId}
          eventType={eventType}
          menuItems={menuItems}
          duplicateCardData={duplicateCardData}
          inputValue={inputValue}
          setInputValue={setInputValue}
          setLoader={setLoader}
        />
      </Form>
      {loader === true && <LoaderGif />}
    </React.Fragment>
  );
};

const EventBox = ({
  register,
  errors,
  control,
  categoryDropdown,
  setCategoryDropdown,
  onSubmitCreateEvent,
  setValue,
  eventId,
  eventType,
  menuItems,
  inputValue,
  setInputValue,
  duplicateCardData,
  setLoader,
}) => {
  const timezoneList = timezone_list_json;
  const categoryList = category_list_json;
  delete category_list_json[1];
  const [stateLoaded, setStateLoaded] = useState(false);

  const e_category =
    eventId !== "new" && duplicateCardData.event_category
      ? duplicateCardData.event_category
      : {};
  const [eventCategory, setEventCategory] = useState(
    categoryList.filter((i) => i.value === e_category)[0]
  );
  const tzone =
    eventId !== "new" &&
    duplicateCardData.base_configuration[0].event_configuration
      .event_details_configuration.timezone
      ? duplicateCardData.base_configuration[0].event_configuration
          .event_details_configuration.timezone
      : moment.tz.guess();
  const [timezoneValue, setTimezoneValue] = useState(
    timezoneList.filter((i) => i.value === tzone)[0]
  );
  const [startDate, setStartDate] = useState(
    duplicateCardData.access_id
      ? moment
          .tz(
            duplicateCardData.base_configuration[0].event_configuration
              .event_details_configuration.start_date,
            tzone
          )
          .format("D MMM, yyyy hh:ss a")
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    duplicateCardData.access_id
      ? moment
          .tz(
            duplicateCardData.base_configuration[0].event_configuration
              .event_details_configuration.end_date,
            tzone
          )
          .format("D MMM, yyyy hh:ss a")
      : new Date()
  );
  const [eventImage, setEventImage] = useState(
    eventId !== "new" &&
      duplicateCardData.base_configuration[0].event_configuration
        .event_details_configuration
      ? duplicateCardData.base_configuration[0].event_configuration
          .event_details_configuration.event_image
      : undefined
  );
  const [voiceOverFemale, setVoiceOverFemale] = useState(
    eventId !== "new" &&
      duplicateCardData.base_configuration[0].event_configuration
        .intro_configuration.avatar_configuration
      ? duplicateCardData.base_configuration[0].event_configuration
          .intro_configuration.avatar_configuration.voice_over_female
      : undefined
  );
  const [voiceOverMale, setVoiceOverMale] = useState(
    eventId !== "new" &&
      duplicateCardData.base_configuration[0].event_configuration
        .intro_configuration.avatar_configuration
      ? duplicateCardData.base_configuration[0].event_configuration
          .intro_configuration.avatar_configuration.voice_over_male
      : undefined
  );
  const [introVideo, setIntroVideo] = useState(
    eventId !== "new" &&
      duplicateCardData.base_configuration[0].event_configuration
        .intro_configuration.video_configuration
      ? duplicateCardData.base_configuration[0].event_configuration
          .intro_configuration.video_configuration.intro_video
      : undefined
  );
  const [textToSpeechEnabled, setTextToSpeechEnabled] = useState(false);
  const [voiceOverTextValue, setVoiceOverText] = useState(
    eventId !== "new" &&
      duplicateCardData.base_configuration[0].event_configuration
        .intro_configuration.avatar_configuration
      ? duplicateCardData.base_configuration[0].event_configuration
          .intro_configuration.avatar_configuration.voice_over_text
      : null
  );
  const [selectedIntroType, setSelectedIntroType] = useState(
    eventId !== "new"
      ? duplicateCardData.base_configuration[0].event_configuration
          .intro_configuration.intro_type === "Live"
        ? 0
        : duplicateCardData.base_configuration[0].event_configuration
            .intro_configuration.intro_type === "Avatar"
        ? 1
        : 2
      : 0
  );

  const handleStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
  };

  const setIntroType = (data) => {
    setSelectedIntroType(data[0]);
    setValue(
      "base_configuration[0].event_configuration.intro_configuration.intro_type",
      data[1]
    );
  };

  const setTimezoneToForm = (val) => {
    setValue(
      "base_configuration[0].event_configuration.event_details_configuration.timezone",
      val
    );
  };

  const setEventCategoryToForm = (val) => {
    setValue("event_category", val);
  };

  const changeMedia = (changeField, media) => {
    switch (changeField) {
      case "event_image":
        setEventImage(media);
        setValue(
          "base_configuration[0].event_configuration.event_details_configuration.event_image",
          media
        );
        break;
      case "voice_over_female":
        setVoiceOverFemale(media);
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female",
          media
        );
        break;
      case "voice_over_male":
        setVoiceOverMale(media);
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male",
          media
        );
        break;
      case "intro_video":
        setIntroVideo(media);
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video",
          media
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!stateLoaded) {
      register(
        "base_configuration[0].event_configuration.event_details_configuration.event_image"
      );
      register(
        "base_configuration[0].event_configuration.intro_configuration.intro_type"
      );
      register(
        "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female"
      );
      register(
        "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male"
      );
      register(
        "base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video"
      );
      register(
        "base_configuration[0].event_configuration.event_details_configuration.timezone"
      );
      register("event_category");

      if (eventId !== "new") {
        setValue(
          "base_configuration[0].event_configuration.event_details_configuration.event_image",
          duplicateCardData.base_configuration[0].event_configuration
            .event_details_configuration.event_image
        );
        if (
          duplicateCardData.base_configuration[0].event_configuration
            .intro_configuration.avatar_configuration
        ) {
          setValue(
            "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female",
            duplicateCardData.base_configuration[0].event_configuration
              .intro_configuration.avatar_configuration.voice_over_female
          );
          setValue(
            "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male",
            duplicateCardData.base_configuration[0].event_configuration
              .intro_configuration.avatar_configuration.voice_over_male
          );
        }
        if (
          duplicateCardData.base_configuration[0].event_configuration
            .intro_configuration.video_configuration
        ) {
          setValue(
            "base_configuration[0].event_configuration.intro_configuration.video_configuration.intro_video",
            duplicateCardData.base_configuration[0].event_configuration
              .intro_configuration.video_configuration.intro_video
          );
        }
      } else {
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.intro_type",
          "Live"
        );
      }
      setStateLoaded(true);
    }
  });

  const { textToSpeechMedia, uploadMediaData } = useSelector(
    (state) => state.auth
  );
  const [audioGender, setAudioGender] = useState("");

  // after get response from API
  const createFileFromBase64 = (base64File) => {
    let byteCharacters = Base64.atob(base64File);
    var sliceSize = 512;

    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, {
      type: "audio/mp3",
    }); //statement which creates the blob

    let fileName = "";
    const timemillis = new Date().getTime();
    if (audioGender === "FEMALE") {
      fileName = "AVO_F_" + timemillis + ".mp3";
    } else {
      fileName = "AVO_M_" + timemillis + ".mp3";
    }
    let file = new File([blob], fileName, { type: "audio/mp3" });

    return file;
  };

  const uploadAudioFile = (file) => {
    var formdata = new FormData();
    formdata.append("files", file, file.name);
    dispatch(uploadMedia(formdata))
      .then(() => {})
      .catch(() => {});
  };

  useEffect(() => {
    if (textToSpeechMedia) {
      let base64File = textToSpeechMedia.audioContent;
      let file = createFileFromBase64(base64File);
      uploadAudioFile(file);
    }
  }, [textToSpeechMedia]);

  useEffect(() => {
    if (uploadMediaData) {
      if (audioGender === "FEMALE") {
        setVoiceOverFemale(uploadMediaData[0]);
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_female",
          uploadMediaData[0]
        );
        downloadbutton("MALE");
      } else if (audioGender === "MALE") {
        setVoiceOverMale(uploadMediaData[0]);
        setValue(
          "base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_male",
          uploadMediaData[0]
        );
        setLoader(false);
        setAudioGender("");
      }
    }
  }, [uploadMediaData]);

  const voiceOverTextChange = (e) => {
    setVoiceOverText(e.target.value);
    setTextToSpeechEnabled(true);
    setVoiceOverFemale(undefined);
    setVoiceOverMale(undefined);
  };

  const dispatch = useDispatch();
  const downloadbutton = (gender) => {
    setAudioGender(gender);
    setTextToSpeechEnabled(false);
    setLoader(true);
    let voiceOverData = {
      audioConfig: {
        audioEncoding: "MP3",
        pitch: 0,
        speakingRate: 1,
      },
      input: {
        text: voiceOverTextValue,
      },
      voice: {},
    };

    if (gender === "FEMALE") {
      voiceOverData.voice = {
        languageCode: "en-US",
        //  "gender": "FEMALE",
        name: "en-US-Wavenet-G",
      };
    } else if (gender === "MALE") {
      voiceOverData.voice = {
        languageCode: "en-US",
        //  "gender": "MALE",
        name: "en-US-Wavenet-I",
      };
    }
    dispatch(googleTextToSpeech(voiceOverData))
      .then(() => {})
      .catch((error) => {});
  };

  return (
    <Container>
      <div className="event_box">
        <div className="event_box_pd">
          <Row>
            {menuItems.length > 0 && (
              <Col lg={6} md={6} sm={6}>
                <FormGroup className="material-group mb-0">
                  <FormControl variant="outlined">
                    <Autocomplete
                      id="demo-simple-select-outlined"
                      options={menuItems}
                      value={inputValue}
                      onChange={(event, newValue) => {
                        if (newValue !== null)
                          window.location.href =
                            "/admin/new-event/" +
                            newValue.type +
                            "/" +
                            newValue.value;
                        // else
                        //    window.location.href = "/admin/new-event/others/new";
                      }}
                      onInputChange={(event, newInputValue) => {}}
                      getOptionLabel={(option) => option.label}
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Duplicate"
                          variant="outlined"
                          name="duplicate_event"
                          inputRef={register({
                            required: true,
                          })}
                        />
                      )}
                    />
                    {errors.duplicate_event?.type === "required" && (
                      <p className="error_msg">Please select duplicate.</p>
                    )}
                  </FormControl>
                  <HtmlTooltip
                    title={
                      <Typography color="inherit">
                        {cms_button_details_json.industry_vertical_field_detail}
                      </Typography>
                    }
                  >
                    <p className="field_info_text">
                      <img
                        className=""
                        src="/images/info_text_icon.svg"
                        alt=""
                      />
                    </p>
                  </HtmlTooltip>
                </FormGroup>
              </Col>
            )}
            <Col lg={6} md={6} sm={6}>
              <FormGroup className="material-group mb-0">
                {categoryList.length > 0 && (
                  <FormControl variant="outlined">
                    <Autocomplete
                      id="demo-simple-select-outlined-1"
                      options={categoryList}
                      value={eventCategory ? eventCategory : ""}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setEventCategory(newValue);
                          setCategoryDropdown(false);
                          setEventCategoryToForm(newValue.value);
                        }
                      }}
                      onInputChange={(event, newInputValue) => {}}
                      getOptionLabel={(option) =>
                        option.label ? option.label : ""
                      }
                      getOptionSelected={(option, value) =>
                        option.value === value.value
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Industry Vertical"
                          variant="outlined"
                        />
                      )}
                      name="event_category"
                    />
                    {categoryDropdown === true && (
                      <p className="error_msg">
                        Please select Industry Vertical.
                      </p>
                    )}
                  </FormControl>
                )}
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {cms_button_details_json.event_language_field_detail}
                    </Typography>
                  }
                >
                  <p className="field_info_text">
                    <img className="" src="/images/info_text_icon.svg" alt="" />
                  </p>
                </HtmlTooltip>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <div className="radio_check_div">
          <Row>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <FormGroup className="material-group no_margin">
                <FormControl variant="outlined">
                  <InputLabel htmlFor="EventName" className="label-caption">
                    Event Name
                  </InputLabel>
                  <OutlinedInput
                    id="EventName"
                    type="text"
                    name="event_name"
                    className="material"
                    inputRef={register({
                      required: true,
                    })}
                  />
                  {errors.event_name?.type === "required" && (
                    <p className="error_msg">Please select event name.</p>
                  )}
                </FormControl>
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {cms_button_details_json.event_name_field_detail}
                    </Typography>
                  }
                >
                  <p className="field_info_text">
                    <img className="" src="/images/info_text_icon.svg" alt="" />
                  </p>
                </HtmlTooltip>
              </FormGroup>
              <FormGroup className="material-group no_margin">
                <FormControl variant="outlined">
                  <InputLabel
                    htmlFor="EventDescription"
                    className="label-caption"
                  >
                    Event Description
                  </InputLabel>
                  <OutlinedInput
                    id="EventDescription"
                    type="text"
                    name="event_description"
                    className="material"
                    inputRef={register({
                      required: false,
                    })}
                  />
                </FormControl>
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {cms_button_details_json.event_description_field_detail}
                    </Typography>
                  }
                >
                  <p className="field_info_text">
                    <img className="" src="/images/info_text_icon.svg" alt="" />
                  </p>
                </HtmlTooltip>
              </FormGroup>
              <FormGroup className="material-group">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <div className="d-flex align-items-center">
                    <div>
                      <KeyboardDateTimePicker
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-start-1"
                        label="Start Date"
                        format="dd MMM, yyyy hh:mm a"
                        value={startDate}
                        minDate={moment().format("D MMM, yyyy hh:ss a")}
                        onChange={handleStartDateChange}
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                        name="base_configuration[0].event_configuration.event_details_configuration.start_date"
                        className="mt-0 mb-0 mr-4 full_clickable"
                        inputRef={register({
                          required: {
                            value: true,
                            message: "Please select start date.",
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="base_configuration[0].event_configuration.event_details_configuration.start_date"
                        as={<p className="error_msg"></p>}
                      />
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {cms_button_details_json.start_date_field_detail}
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </div>
                    <div>
                      <KeyboardDateTimePicker
                        margin="normal"
                        inputVariant="outlined"
                        id="date-picker-end-1"
                        label="End Date"
                        format="dd MMM, yyyy hh:mm a"
                        value={endDate}
                        minDate={moment().format("D MMM, yyyy hh:ss a")}
                        onChange={handleEndDateChange}
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                        name="base_configuration[0].event_configuration.event_details_configuration.end_date"
                        className="m-0 full_clickable"
                        inputRef={register({
                          required: {
                            value: true,
                            message: "Please select end date.",
                          },
                        })}
                      />
                      <ErrorMessage
                        errors={errors}
                        name="base_configuration[0].event_configuration.event_details_configuration.end_date"
                        as={<p className="error_msg"></p>}
                      />
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {cms_button_details_json.end_date_field_detail}
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </div>
                  </div>
                </MuiPickersUtilsProvider>
              </FormGroup>
              <FormGroup className="material-group timezone_field">
                <FormControl variant="outlined">
                  <Autocomplete
                    id="demo-simple-select-outlined-1"
                    options={timezoneList}
                    value={timezoneValue}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        setTimezoneValue(newValue);
                        setTimezoneToForm(newValue.value);
                      }
                    }}
                    onInputChange={(event, newInputValue) => {}}
                    getOptionLabel={(option) =>
                      option.label ? option.label : ""
                    }
                    getOptionSelected={(option, value) =>
                      option.value === value.value
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Timezone"
                        variant="outlined"
                      />
                    )}
                    name="base_configuration[0].event_configuration.event_details_configuration.timezone"
                  />
                </FormControl>
                <HtmlTooltip
                  title={
                    <Typography color="inherit">
                      {cms_button_details_json.timezone_field_detail}
                    </Typography>
                  }
                >
                  <p className="field_info_text">
                    <img className="" src="/images/info_text_icon.svg" alt="" />
                  </p>
                </HtmlTooltip>
              </FormGroup>
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <DropZonePlaceHolder
                holder={
                  eventImage && eventImage.formats
                    ? ENV_MEDIA_URL + eventImage.formats.thumbnail.url
                    : ""
                }
                type="image"
                fileName={eventImage ? eventImage.name : ""}
                fileSizeText=""
                labelName="Event Image"
                ButtonText="Browse"
                changeselectedmedia={(media) =>
                  changeMedia("event_image", media)
                }
                removeMedia={() => changeMedia("event_image", null)}
                category={
                  eventCategory && eventCategory.value
                    ? eventCategory.value
                    : "all"
                }
              />
              <HtmlTooltip
                title={
                  <Typography color="inherit">
                    {cms_button_details_json.event_image_field_detail}
                  </Typography>
                }
              >
                <p className="field_info_text">
                  <img className="" src="/images/info_text_icon.svg" alt="" />
                </p>
              </HtmlTooltip>
            </Col>
          </Row>
        </div>
      </div>
      <div className="event_box event_box_2">
        <Tabs
          selectedIndex={selectedIntroType}
          onSelect={(tabIndex) =>
            setIntroType([
              tabIndex,
              tabIndex === 0 ? "Live" : tabIndex === 1 ? "Avatar" : "Video",
            ])
          }
        >
          <div className="tabs_header">
            <TabList>
              <Tab>
                <p className="tabs_title">
                  <span className="circle_span"></span>
                  <span>Live Presenter Driven</span>
                </p>
              </Tab>
              <Tab>
                <p className="tabs_title">
                  <span className="circle_span"></span>
                  <span>Avatar Driven</span>
                </p>
              </Tab>
              <Tab>
                <p className="tabs_title">
                  <span className="circle_span"></span>
                  <span>Video Driven</span>
                </p>
              </Tab>
            </TabList>
            <p className="common_text">
              These options can be later edited in the Intro section of the
              Event Settings.
            </p>
          </div>
          <div className="tabs_body">
            <TabPanel>
              <div className="live_presentr_driven">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup className="material-group m-0">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="PresenterName"
                          className="label-caption"
                        >
                          Presenter Name
                        </InputLabel>
                        <OutlinedInput
                          id="PresenterName"
                          type="text"
                          name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.name"
                          className="material"
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter presenter name.",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.name"
                          as={<p className="error_msg"></p>}
                        />
                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="PresenterEmailAddress"
                          className="label-caption"
                        >
                          Presenter Email Address
                        </InputLabel>
                        <OutlinedInput
                          id="PresenterEmailAddress"
                          type="text"
                          name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.email"
                          className="material"
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter presenter email address.",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.email"
                          as={<p className="error_msg"></p>}
                        />
                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup className="material-group m-0">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="MeetingURL"
                          className="label-caption"
                        >
                          WebeX Meeting URL
                        </InputLabel>
                        <OutlinedInput
                          id="MeetingURL"
                          type="text"
                          name="base_configuration[0].event_configuration.meeting_url"
                          className="material"
                          inputRef={register({
                            required: false,
                          })}
                        />
                      </FormControl>
                      <HtmlTooltip
                        title={
                          <Typography color="inherit">
                            {cms_button_details_json.meeting_url_field_detail}
                          </Typography>
                        }
                      >
                        <p className="field_info_text">
                          <img
                            className=""
                            src="/images/info_text_icon.svg"
                            alt=""
                          />
                        </p>
                      </HtmlTooltip>
                    </FormGroup>
                    {/* <FormGroup className="material-group m-0">
                      <FormControl variant="outlined">
                        <InputLabel htmlFor="WebxURL" className="label-caption">
                          WebeX Meeting URL
                        </InputLabel>
                        <OutlinedInput
                          id="WebxURL"
                          type="text"
                          className="material"
                          name="base_configuration[0].event_configuration.meeting_url"
                        />
                      </FormControl>
                    </FormGroup> */}
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup className="material-group m-0">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="CohostName"
                          className="label-caption"
                        >
                          Cohost Name
                        </InputLabel>
                        <OutlinedInput
                          id="CohostName"
                          type="text"
                          name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.cohost_name"
                          className="material"
                          inputRef={register({
                            required: {
                              value: false,
                              message: "Please enter Cohost name.",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.cohost_name"
                          as={<p className="error_msg"></p>}
                        />
                      </FormControl>
                    </FormGroup>
                  </Col>
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup className="material-group">
                      <FormControl variant="outlined">
                        <InputLabel
                          htmlFor="CohostEmailAddress"
                          className="label-caption"
                        >
                          Cohost Email Address
                        </InputLabel>
                        <OutlinedInput
                          id="CohostEmailAddress"
                          type="text"
                          name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.cohost_email"
                          className="material"
                          inputRef={register({
                            required: {
                              value: false,
                              message: "Please enter Cohost email address.",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="base_configuration[0].event_configuration.intro_configuration.presenter_configuration.cohost_email"
                          as={<p className="error_msg"></p>}
                        />
                      </FormControl>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="avatar_driven">
                <Row className="pb-4 border_bottom_hr">
                  <Col lg={6} md={6} sm={6}>
                    <FormGroup className="material-group mb-0">
                      <FormControl
                        variant="outlined"
                        className="cover_with_button"
                      >
                        <InputLabel
                          htmlFor="voiceOverText"
                          className="label-caption"
                        >
                          Voice Over Text
                        </InputLabel>
                        <OutlinedInput
                          id="voiceOverText"
                          type="text"
                          multiline
                          onChange={voiceOverTextChange}
                          rows={5}
                          name="base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_text"
                          className="material"
                          inputRef={register({
                            required: {
                              value: true,
                              message: "Please enter Voice Over Text.",
                            },
                          })}
                        />
                      </FormControl>
                      <div className="cover_text_speech_btn">
                        <HtmlTooltip
                          title={
                            <Typography color="inherit">
                              {
                                cms_button_details_json.voice_over_text_field_detail
                              }
                            </Typography>
                          }
                        >
                          <p className="field_info_text">
                            <img
                              className=""
                              src="/images/info_text_icon.svg"
                              alt=""
                            />
                          </p>
                        </HtmlTooltip>
                        <Button
                          disabled={!textToSpeechEnabled}
                          variant="contained"
                          color="primary"
                          className="text-white"
                          onClick={() => downloadbutton("FEMALE")}
                        >
                          Convert Text to Speech
                        </Button>
                      </div>
                      <ErrorMessage
                        errors={errors}
                        name="base_configuration[0].event_configuration.intro_configuration.avatar_configuration.voice_over_text"
                        as={<p className="error_msg"></p>}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {voiceOverFemale && (
                  <Row className="pt-4 pb-5">
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="AvatarFemale"
                            className="label-caption"
                          >
                            Female Avatar
                          </InputLabel>
                          <OutlinedInput
                            id="AvatarFemale"
                            type="text"
                            name="base_configuration[0].event_configuration.intro_configuration.avatar_configuration.avatar_female"
                            value="Claudia"
                            className="material"
                            disabled
                            inputRef={register({ required: false })}
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <DropZonePlaceHolder
                        holder={
                          voiceOverFemale
                            ? ENV_MEDIA_URL + voiceOverFemale.url
                            : ""
                        }
                        type="audio"
                        fileName={voiceOverFemale ? voiceOverFemale.name : ""}
                        fileSizeText=""
                        labelName="Voice Over Female"
                        ButtonText=""
                        changeselectedmedia={(media) =>
                          changeMedia("voice_over_female", media)
                        }
                        category={
                          eventCategory && eventCategory.value
                            ? eventCategory.value
                            : "all"
                        }
                      />
                    </Col>
                  </Row>
                )}
                {voiceOverMale && (
                  <Row className="pb-4">
                    <Col lg={6} md={6} sm={6}>
                      <FormGroup className="material-group mb-0">
                        <FormControl variant="outlined">
                          <InputLabel
                            htmlFor="AvatarMale"
                            className="label-caption"
                          >
                            Male Avatar
                          </InputLabel>
                          <OutlinedInput
                            id="AvatarMale"
                            type="text"
                            name="base_configuration[0].event_configuration.intro_configuration.avatar_configuration.avatar_male"
                            value="Eric"
                            className="material"
                            disabled
                            inputRef={register({ required: false })}
                          />
                        </FormControl>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={6} sm={6}>
                      <DropZonePlaceHolder
                        holder={
                          voiceOverMale ? ENV_MEDIA_URL + voiceOverMale.url : ""
                        }
                        type="audio"
                        fileName={voiceOverMale ? voiceOverMale.name : ""}
                        fileSizeText=""
                        labelName="Voice Over Male"
                        ButtonText=""
                        changeselectedmedia={(media) =>
                          changeMedia("voice_over_male", media)
                        }
                        category={
                          eventCategory && eventCategory.value
                            ? eventCategory.value
                            : "all"
                        }
                      />
                    </Col>
                  </Row>
                )}
              </div>
            </TabPanel>
            <TabPanel>
              <div className="video_driven">
                <Row>
                  <Col lg={6} md={6} sm={6}>
                    <DropZonePlaceHolder
                      holder={introVideo ? ENV_MEDIA_URL + introVideo.url : ""}
                      type="video"
                      fileName={introVideo ? introVideo.name : ""}
                      fileSizeText=""
                      labelName="Intro Video"
                      ButtonText="Browse"
                      changeselectedmedia={(media) =>
                        changeMedia("intro_video", media)
                      }
                      removeMedia={() => changeMedia("intro_video", null)}
                      category={
                        eventCategory && eventCategory.value
                          ? eventCategory.value
                          : "all"
                      }
                    />
                    {/* {introVideo ? null : <p className="Please select video."></p>} */}
                  </Col>
                </Row>
              </div>
            </TabPanel>
          </div>
        </Tabs>
      </div>
    </Container>
  );
};
